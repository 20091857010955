import React from "react"
import { graphql, Link } from "gatsby"

import HomeHero from "../components/homeHero"

// import Layout from "./src/components/layout"

function IndexPage({ data: { allGraphCmsPost, heroImage } }) {
  return (
    <div>
      <HomeHero />
    </div>
  )
}

export const indexPageQuery = graphql`
  {
    allGraphCmsPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        date: formattedDate
        excerpt
        slug
        title
      }
    }
  }
`

export default IndexPage
