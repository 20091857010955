import React from "react"
import { graphql } from "gatsby"
import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { StaticImage } from "gatsby-plugin-image"

import { SocialIcon } from "react-social-icons"
import { useQueryParam, NumberParam, StringParam } from "use-query-params"

import Slider from "react-slick"

export class ImageSlider extends React.Component {
  render() {
    var settings = {
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 1500,
    }
    return (
      <div className="container">
        <Slider {...settings}>
          <div>
            <StaticImage src="../../content/assets/picanha-1x1-web-1.jpg" alt="Picanha" />
          </div>
          <div>
            <StaticImage
              src="../../content/assets/winston-quinn-raspberry-1x1-web.jpg"
              alt="Gin and Raspberry Tonic"
            />
          </div>
          <div>
            <StaticImage src="../../content/assets/pork-belly-1x1-web.jpg" alt="Pork Belly" />
          </div>
          <div>
            <StaticImage src="../../content/assets/creme-brule-1x1-web.jpg" alt="Creme Brule" />
          </div>

          <div>
            <StaticImage src="../../content/assets/brisket-1x1-web.jpg" alt="Dry aged brisket" />
          </div>
          <div>
            <StaticImage
              src="../../content/assets/patient-wolf-gin-blueberry-1x1-web.jpg"
              alt="Gin and Blueberry Tonic"
            />
          </div>
          <div>
            <StaticImage
              src="../../content/assets/patient-wolf-gin-rosemary-1x1-web.jpg"
              alt="Gin and Rosemary Tonic"
            />
          </div>
        </Slider>
      </div>
    )
  }
}

const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Marketplace", href: "#" },
  { name: "Company", href: "#" },
]

function FormSubmitted() {
  return (
    <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
      <p className="text-base font-medium text-gray-900">
        Thank you! I'll let you know when there's more!
      </p>
    </div>
  )
}

function DisplayForm() {
  return (
    <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
      <p className="text-base font-medium text-gray-900">
        Sign up to get notified when it’s ready.
      </p>
      <form
        name="comingsoon"
        action="/?e=1"
        data-netlify="true"
        method="POST"
        className="mt-3 sm:flex">
        <label htmlFor="email" className="sr-only">
          Email
        </label>
        <input
          type="email"
          name="email"
          id="email"
          className="block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 border-gray-300"
          placeholder="Enter your email"
        />
        <input type="hidden" name="form-name" value="comingsoon"></input>
        <button
          type="submit"
          className="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto">
          Notify me
        </button>
      </form>
      <p className="mt-3 text-sm text-gray-500">
        We care about the protection of your data and won't spam you.
      </p>
    </div>
  )
}

export default function HomeHero() {
  const [paramEmail, setParamEmail] = useQueryParam("e", StringParam)
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-0" aria-hidden="true">
        <svg
          className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8"
          width={640}
          height={784}
          fill="none"
          viewBox="0 0 640 784">
          <defs>
            <pattern
              id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
              x={118}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse">
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect y={72} width={640} height={640} className="text-gray-50" fill="currentColor" />
          <rect
            x={118}
            width={404}
            height={784}
            fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"
          />
        </svg>
      </div>

      <div className="relative pt-6 pb-16 sm:pb-24 lg:pb-32">
        {/* <Popover>
          <nav
            className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
            aria-label="Global">
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href="#">
                  <span className="sr-only">Workflow</span>
                  <img
                    className="h-8 w-auto sm:h-10"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt=""
                  />
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden md:block md:ml-10 md:space-x-10">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="font-medium text-gray-500 hover:text-gray-900">
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
            <div className="hidden md:block text-right">
              <span className="inline-flex rounded-md shadow-md ring-1 ring-black ring-opacity-5">
                <a
                  href="#"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50">
                  Log in
                </a>
              </span>
            </div>
          </nav>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <Popover.Panel
              focus
              className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                      alt=""
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close main menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">
                      {item.name}
                    </a>
                  ))}
                </div>
                <a
                  href="#"
                  className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100">
                  Log in
                </a>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover> */}

        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <h1>
                <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                  Coming soon
                </span>
                <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                  <span className=" text-red-500">Fire</span>
                  <span className=" text-blue-600"> and cocktails</span>
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                Andrew Hatfield loves cooking with fire and enjoying cocktails. Driven by flavour,
                experiences, and highlighting Australia's amazing and unique produce.
              </p>
              <div className="mt-4 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0 flex align-middle items-center flex-row text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                Connect with Andrew:
                {/* <a className="mx-3 align-middle" href="https://www.instagram.com/fireandcocktails">
                  <SiInstagram />
                </a> */}
                {/* <a href="https://www.facebook.com/fireandcocktails">
                  <SiFacebook />
                </a> */}
                <SocialIcon
                  className="mx-3 text-base w-0.5"
                  url="https://www.instagram.com/fireandcocktails"
                />
                <SocialIcon url="https://www.facebook.com/fireandcocktails" />
              </div>
              {paramEmail == "1" ? <FormSubmitted /> : <DisplayForm />}
            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <svg
                className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
                width={640}
                height={784}
                fill="none"
                viewBox="0 0 640 784"
                aria-hidden="true">
                <defs>
                  <pattern
                    id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                    x={118}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse">
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  y={72}
                  width={640}
                  height={640}
                  className="text-gray-50"
                  fill="currentColor"
                />
                <rect
                  x={118}
                  width={404}
                  height={784}
                  fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"
                />
              </svg>
              <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                {/* <StaticImage src="../../content/assets/picanha-1x1-web-1.jpg" alt="" /> */}
                <ImageSlider className="w-full" />
                {/* <GatsbyImage image={heroImage} alt="picanha" /> */}
                {/* <img className="w-full" src={heroImage} alt="Picanha" /> */}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
